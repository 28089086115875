<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Create New User</h1>
                <hr>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                        <form @submit.prevent="editUsers" class="row p-2">
                            <div class="col-xl-12 form-group mb-3">
                                <label for="">Fullname</label>
                                <input v-model="user.name" type="text" required class="form-control text-grey-900 font-xsss fw-600">                        
                            </div>
                            <div class="col-xl-12 form-group mb-3">
                                <label for="">Username</label>
                                <input v-model="user.username" type="text" required class="form-control text-grey-900 font-xsss fw-600">                        
                            </div>
                            <div class="col-xl-12 form-group mb-3">
                                <label for="">Email</label>
                                <input v-model="user.email" type="email" required class="form-control text-grey-900 font-xsss fw-600">                        
                            </div>
                            <div class="col-xl-12 form-group mb-3">
                                <label for="">Role :</label>
                                <select v-model="user.role_id" required class="form-control" v-if="user.role_id == 1">
                                    <option value="">Select Role</option>
                                    <option :value="item.id" v-for="(item, index) in roles.filter(x => x.id != 2)" :key="index">{{ item.name }}</option>
                                </select>
                                <select v-model="user.role_id" required class="form-control" v-else>
                                    <option value="">Select Role</option>
                                    <option :value="item.id" v-for="(item, index) in roles.filter(x => x.id != 2 && x.id != 1)" :key="index">{{ item.name }}</option>
                                </select>
                            </div>
                            <div class="col-xl-12 form-group text-right my-4">
                                <router-link to="/admin/users" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                                <input type="Submit" class="btn btn-current" value="Submit">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "EditUsersAdmin",
        data(){
            return {
                paramsId: this.$route.params.idUser,
                roles: [],
                user: {},
                form: {
                    username: '',
                    name: '',
                    email: '',
                    role_id: ''
                },
                validationErrors: ''
            }
        },
        created() {
            this.getRoles()
            this.getUser()
        },
        methods: {
            async getRoles() {
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/role`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.roles = res.data
                })
            },
            async getUser(){
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/user?id=${this.paramsId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.user = res.data
                }).catch((err) => console.error(err))
            },
            async editUsers(){
                var data = {
                    id: this.paramsId,
                    username: this.user.username,
                    name: this.user.name,
                    email: this.user.email,
                    role_id: this.user.role_id,
                    _method : 'patch',
                }
                await axios.post(`${process.env.VUE_APP_URL_API}/admin/user`, data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.token}`
                    },
                }).then(res => {
                    this.$swal({
                        toast: true,
                        title: 'Success Edit User !',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    this.$router.push({ name: 'UsersAdmin' })
                }).catch(err => {
                    if (err.response.status == 422){
                        this.validationErrors = Object.values(err.response.data.errors);
                        // this.$toastr.error(this.validationErrors[0][0], 'failed Create New Course !');
                        this.$swal({
                            toast: true,
                            title: 'Failed Edit User !',
                            text: this.validationErrors[0][0],
                            icon: 'error',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    }
                    console.log(err.response)
                })
            }
        }
    }
</script>
